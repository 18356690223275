<style lang="sass" scoped>

</style>
<template lang="pug">
div
  .bg-white.shadow-sm.mb-2
    .p-3
      h5.title 항목을 선택해주세요.

      hr
      //- pre {{selected_keys}}
      div(v-if='document && document.config && document.config.cols')
        div(v-for='col in document.config.cols')
          b-form-checkbox(v-model='selected_keys[col.key]' value='Y' unchecked-value='N')
            | {{col.label}}
        hr
        b-form-checkbox(v-model='selected_keys["__ID"]' value='Y' unchecked-value='N') ID
        b-form-checkbox(v-model='selected_keys["__LIST"]' value='Y' unchecked-value='N') 목록이름
        b-form-checkbox(v-model='selected_keys["__CREATED_AT"]' value='Y' unchecked-value='N') 생성일
        b-form-checkbox(v-model='selected_keys["__UPDATED_AT"]' value='Y' unchecked-value='N') 변경일
      .mb-4
      button.btn.btn-primary.mr-2(type='button' @click='open_table') 열기
      button.btn.btn-light.text-primary.mr-2(type='button' @click='open_table(`print`)') 프린트
      button.btn.btn-light.text-primary.mr-2(type='button' @click='open_table(`download`)') 엑셀 다운로드
  //- (style='margin-top: -61px; position: relative; z-index: 1')
  //- div.container-search.bg-white.shadow-sm.pl-2
    router-link(:to='`/property/${property_id}/activity`')
      span.text-dark(style='font-size: 18px; line-height: 50px')
        b-icon-chevron-left
        | 뒤로
    strong.text-secondary.ml-4.mr-2 {{document.name}}

  //- form(@submit.prevent='submit')
    table.table(v-if='row.id')
      tbody
        tr
          th.bg-light.w-25.text-right ID
          td
            input.form-control(type='text' v-model='row.external_id' readonly autofocus)
        tr(v-for='(col, $index) in document.config.cols')
          th.bg-light.w-25.text-right {{col.label}}
          td
            input.form-control(type='text' v-model='row.json[col.key]')
        tr
          th
          td
            button.btn.btn-primary(type='submit' :disabled='saving') {{saving_text}}
            button.btn.btn-default.text-danger.float-right(type='button' @click='remove') 삭제


</template>

<script>

export default {
  name: 'index',
  props: ['property_id', 'document_id', 'record_id', 'on_updated'],
  components: {

  },
  computed: {

  },
  watch: {
    '$store.state.documents'() {
      this.load()
    },
    'document_id'() {
      this.load()
    },
    'record_id'() {
      this.load()
    },
  },
  data() {
    return {
      done: false,
      saving: false,
      saving_text: '저장',
      document: {},
      row: {},

      selected_keys: {},
    }
  },
  async mounted() {
    // await this.$store.dispatch('status flows', this.property_id)
    // this.loaded = true
    // this.load()
    if (this.$store.state.documents) {
      this.load()
    }
  },
  methods: {
    async load() {
      this.document = Object.assign({}, this.$store.state.documents_by_id[this.document_id])
      if (!this.document.id) {
        this.error = '해당 목록을 찾지 못했습니다.'
        return
      }
      this.document.config.cols.forEach(e => {
        this.$set(this.selected_keys, e.key, 'Y')
      })

      try {

        this.done = true
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },
    open_table(action = '') {
      const opt = {
        f: Object.entries(this.selected_keys).filter( ([k, v]) => v == 'Y').map(e => e[0]),
      }
      if (action) {
        opt.action = action
      }
      // console.log(opt, Object.entries(this.selected_keys))
      window.open(`/property/${this.property_id}/customer/${this.document_id}/export/result?o=${encodeURIComponent(JSON.stringify(opt))}`)
    },
  },
}
</script>
